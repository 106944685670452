export const API_URL = 'https://smart-mirror-api.tranvanphuc.com/api';
// export const API_URL = 'https://localhost:44337/api';

export const API_ROUTES = {
    signin: '/Account/signin',
    loginGoogle: '/GoogleAuths/ExternalLogin',
    getUserInfo: '/Account/GetUserInfo',
    getUserConfig: '/UserConfig/GetUserConfig',
    getPing: '/PingWebsite/GetListPingWebsite',
    addPing: '/PingWebsite/CreatePingWebsite',
    deletePing: '/PingWebsite/DeletePingWebsite',
    getCameraGTs: '/CameraGT/GetCameraGTOfUser',
    addCameraGT: '/CameraGT/Create',
    deleteCameraGT: '/CameraGT/Delete',
    getAllAddress: '/Address',
    getUserAddress: '/Account/GetAddressOfUser',
    getWeather: '/Weather/GetWeather',
    getNews: '/News',
    getFootballFixtures: '/Football/fixtures',
};
