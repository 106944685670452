import { API_ROUTES } from '../constants/apiCons';
import { instance } from './instanceAPI';

export const getNewsApi = async (token: string) => {
    const res = await instance.get(API_ROUTES.getNews, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};
