import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNewsApi } from '../../api/news';
import { RootState } from '../../app/store';

export interface News {
    id: number;
    title: string;
    date: string;
    description: string;
    url: string;
}

const initialState: News = {
    id: 0,
    title: '',
    date: '',
    description: '',
    url: '',
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNewsAsync.fulfilled, (state, action) => {
            state = { ...state, ...action.payload };
            return state;
        });
    },
});

export const getNewsAsync = createAsyncThunk(
    'news/getNews',
    async (token: string) => {
        const res = await getNewsApi(token);

        if (res.data.length <= 0) return {};
        return res.data[0];
    }
);

export const selectNews = (state: RootState) => state.news;
export default newsSlice.reducer;
