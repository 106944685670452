import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUserConfig } from '../../api/userConfig';
import { RootState } from '../../app/store';

export interface UserConfigType {
    avatarUrl: string;
    backgroundUrl: string;
    id: number;
    isWeather: boolean;
    isTime: boolean;
    isPing: boolean;
    isNews: boolean;
}

const initialState: UserConfigType = {
    avatarUrl: '',
    backgroundUrl: '',
    id: 0,
    isWeather: true,
    isTime: true,
    isPing: true,
    isNews: true,
};

const userConfigSlice = createSlice({
    name: 'userConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserConfigAsync.fulfilled, (state, action) => {
            state = Object.assign(state, action.payload);
        });
    },
});

export const fetchUserConfigAsync = createAsyncThunk(
    'userConfig/fetchUserConfig',
    async () => {
        const res = await fetchUserConfig();
        return res;
    }
);

export const selectUserConfig = (state: RootState) => state.userConfig;
export default userConfigSlice.reducer;
