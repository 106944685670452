import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addCameraGTApi, getCameraGTs } from '../../api/cameraGT';
import { RootState } from '../../app/store';

export interface CameraGT {
    id: number;
    url: string;
    title: string;
    base64Image: string;
}

const initialState: CameraGT[] = [];

const cameraGTSlice = createSlice({
    name: 'cameraGT',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCameraGTsAsync.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
        builder.addCase(addCameraGT.fulfilled, (state, action) => {
            state.push(action.payload);
            return state;
        });
    },
});

export const fetchCameraGTsAsync = createAsyncThunk(
    'cameraGT/fetchCameraGTs',
    async (token: string) => {
        const res = await getCameraGTs(token);

        return res.data;
    }
);

export const addCameraGT = createAsyncThunk(
    'cameraGT/addCameraGT',
    async ({
        url,
        title,
        token,
    }: {
        url: string;
        title: string;
        token: string;
    }) => {
        const res = await addCameraGTApi(url, title, token);
        return res.data;
    }
);

export const selectCameraGTs = (state: RootState) => state.cameraGT;
export default cameraGTSlice.reducer;
