import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from '../../api/account';
import { RootState } from '../../app/store';

export interface UserInfo {
    id: string;
    firstName: string;
    lastName: string;
    user_Addresses: any;
    user_CameraGTs: any;
    user_NewsTypes: any;
    googleCalendars: any;
    alarms: any;
    websites: any;
    userConfigs: any;
    userName: string;
    normalizedUserName: string;
    email: string;
    normalizedEmail: string;
    emailConfirmed: boolean;
    passwordHash: string;
    securityStamp: string;
    concurrencyStamp: string;
    phoneNumber: any;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnd: any;
    lockoutEnabled: boolean;
    accessFailedCount: number;
}

const initialState: UserInfo = {
    id: '',
    firstName: '',
    lastName: '',
    user_Addresses: null,
    user_CameraGTs: null,
    user_NewsTypes: null,
    googleCalendars: null,
    alarms: null,
    websites: null,
    userConfigs: null,
    userName: '',
    normalizedUserName: '',
    email: '',
    normalizedEmail: '',
    emailConfirmed: false,
    passwordHash: '',
    securityStamp: '',
    concurrencyStamp: '',
    phoneNumber: null,
    phoneNumberConfirmed: false,
    twoFactorEnabled: false,
    lockoutEnd: null,
    lockoutEnabled: false,
    accessFailedCount: 0,
};

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserInfoAsync.fulfilled, (state, action) => {
            state = { ...state, ...action.payload.result };
            return state;
        });
    },
});

export const getUserInfoAsync = createAsyncThunk(
    'userInfo/getUserInfo',
    async (token: string) => {
        const res = await getUserInfo(token);
        return res.data;
    }
);

export const selectUserInfo = (state: RootState) => state.userInfo;

export default userInfoSlice.reducer;
