import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loading from '../components/loading';
import NotFound from '../components/not-found';

const Home = lazy(() => import('../layouts/home'));
const Admin = lazy(() => import('../layouts/admin'));
const Login = lazy(() => import('../layouts/login'));

function Pages() {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/home"
                    element={<Navigate to="/" replace={true} />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default Pages;
