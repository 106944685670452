import { Spin } from 'antd';
import style from './style.module.scss';

function Loading() {
    return (
        <div className={style.loading}>
            <Spin tip="Loading..." />
        </div>
    );
}

export default Loading;
