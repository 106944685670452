import { API_ROUTES } from '../constants/apiCons';
import { getToken } from '../features/authenticate/utils/userToken';
import { instance } from './instanceAPI';

export const fetchUserConfig = async () => {
    const token = getToken();
    if (token === null) {
        return null;
    }

    const res = await instance.get(API_ROUTES.getUserConfig, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return res.data;
};
