import { API_ROUTES } from '../constants/apiCons';
import { instance } from './instanceAPI';

export const getCameraGTs = async (token: string) => {
    const res = await instance.get(API_ROUTES.getCameraGTs, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};

export const addCameraGTApi = async (
    url: string,
    title: string,
    token: string
) => {
    const res = await instance.post(
        API_ROUTES.addCameraGT,
        { url, title },
        { headers: { Authorization: 'Bearer ' + token } }
    );

    return res;
};

export const deleteCameraGTApi = async (id: number, token: string) => {
    const res = await instance.post(
        API_ROUTES.deleteCameraGT + `/${id}`,
        {},
        {
            headers: { Authorization: 'Bearer ' + token },
        }
    );

    return res;
};
