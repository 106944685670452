import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFixturesApi } from '../../api/football';
import { RootState } from '../../app/store';

export interface Football {
    home: string;
    away: string;
    homeImage: string;
    awayImage: string;
    homeGoal: number;
    awayGoal: number;
    date: string;
    timeZone: string;
}

export interface Fixture {
    date: string;
    matchs: Football[];
}

const initialState: Fixture[] = [];

const footballSlice = createSlice({
    name: 'football',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFixturesAsync.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const fetchFixturesAsync = createAsyncThunk(
    'football/fetchFixtures',
    async () => {
        const res = await getFixturesApi();
        return res.data;
    }
);

export const selectFootball = (state: RootState) => state.football;
export default footballSlice.reducer;
