import { gapi } from 'gapi-script';
import './App.css';
import { GOOGLE_CLIENT_ID } from './constants/appCons';
import Pages from './pages';

gapi.load('client:auth2', () => {
    gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        plugin_name: 'chat',
    });
});

function App() {
    return (
        <div className="App">
            <Pages />
        </div>
    );
}

export default App;
