export interface UserResponse {
    token: string;
    userId: string;
    userName: string;
    role: string;
}

export const saveUserToken = (user: UserResponse) => {
    sessionStorage.setItem('user', JSON.stringify(user));
};

export const getUserToken = () => {
    const user = sessionStorage.getItem('user');

    if (user === null) return null;
    else {
        const userRes: UserResponse = JSON.parse(user);
        return userRes;
    }
};

export const removeUserToken = () => {
    sessionStorage.removeItem('user');
};

export const getToken = () => {
    const user = sessionStorage.getItem('user');

    if (user === null) return null;
    else {
        const userRes: UserResponse = JSON.parse(user);
        return userRes.token;
    }
};

export const getUserId = () => {
    const user = sessionStorage.getItem('user');

    if (user === null) return null;
    else {
        const userRes: UserResponse = JSON.parse(user);
        return userRes.userId;
    }
};
