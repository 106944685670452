import { API_ROUTES } from '../constants/apiCons';
import { instance } from './instanceAPI';

export const signInAsync = async (account: {
    username: string;
    password: string;
}) => {
    const result = await instance.post(API_ROUTES.signin, account);
    return result.data;
};

export const loginGoogle = async (googleAccount: {
    provider: string;
    idToken: string;
}) => {
    const result = await instance.post(API_ROUTES.loginGoogle, googleAccount);
    return result;
};

export const getUserInfo = async (token: string) => {
    const res = await instance.get(API_ROUTES.getUserInfo, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};

export const getUserAddress = async (token: string) => {
    const res = await instance.get(API_ROUTES.getUserAddress, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};
