import React from 'react';
import style from './style.module.scss';

function NotFound() {
    return (
        <div className={style.notFound}>
            <div>
                <h2>404.</h2>
                <p>Page not found!</p>
            </div>
        </div>
    );
}

export default NotFound;
