import { API_ROUTES } from '../constants/apiCons';
import { instance } from './instanceAPI';

export const fetchPing = async (token: string) => {
    const res = await instance.get(API_ROUTES.getPing, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};

export const addPingAPI = async (ping: Object, token: string) => {
    const res = await instance.post(API_ROUTES.addPing, ping, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};

export const deletePingAPI = async (id: number, token: string) => {
    const res = await instance.delete(`${API_ROUTES.deletePing}/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return res;
};
