import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPing } from '../../api/ping';
import { RootState } from '../../app/store';

export interface Ping {
    id: number;
    url: string;
    status: boolean;
    date: string;
    userId: string;
}

const initialState: Ping[] = [];

const pingSlice = createSlice({
    name: 'ping',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPingAsync.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
    },
});

export const fetchPingAsync = createAsyncThunk(
    'ping/fetchPing',
    async (token: string) => {
        const res = await fetchPing(token);
        return res.data;
    }
);

export const selectPing = (state: RootState) => state.ping;
export default pingSlice.reducer;
