import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import cameraGTReducer from '../features/camera-gt/cameraGTSlice';
import counterReducer from '../features/counter/counterSlice';
import footballReducer from '../features/football/footballSlice';
import newsReducer from '../features/news/newsSlice';
import pingReducer from '../features/ping/pingSlice';
import userConfigReducer from '../features/user/userConfigSlice';
import userInfoReducer from '../features/user/userInfoSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        userConfig: userConfigReducer,
        ping: pingReducer,
        cameraGT: cameraGTReducer,
        userInfo: userInfoReducer,
        news: newsReducer,
        football: footballReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
